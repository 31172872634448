jQuery(document).ready(function(jQuery) {

	if ( jQuery('.fixar').length ) {
		jQuery('.fixar').wrap( "<div class='fixo'></div>" );
	}

	// Smooth Scroll em âncoras
	jQuery(function() {
	  // jQuery('.origgamimenu a[href*="#"]:not([href="#"])').click(function() {
	  jQuery('.origgamimenu a[href*="#"]:not([href="#"]), .shiftnav a[href*="#"]:not([href="#"])').click(function() {  
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
		  var target = jQuery(this.hash);
		  target = target.length ? target : jQuery('[name=' + this.hash.slice(1) +']');
		  if (target.length) {
			jQuery('html, body').animate({
			  scrollTop: target.offset().top
			}, 1000);
			return false;
		  }
		}
	  });
	});

	if ( jQuery('#billing_postcode_field').length ) {
		jQuery('#billing_postcode_field').insertAfter( jQuery('#billing_country_field') );
	}


	//  STICK FOOTER
	 stickyFooter();
});

function stickyFooter() {
	var footerHeight = jQuery('.site-footer').height();
	jQuery('#page').css('margin-bottom','-'+footerHeight+'px');
	jQuery('.push').css('height',footerHeight+'px');
}

/*var fixar = jQuery( ".fixar" );
var offset = fixar.offset();

jQuery(window).scroll(function() {
	var fixoHeight = jQuery( ".fixo" ).height();
	
	if (jQuery(this).scrollTop() >= offset.top) {
		jQuery('.fixo').addClass('on');
		jQuery('body').css('padding-top',fixoHeight);
	}
	else {
		jQuery('.fixo').removeClass('on');
		jQuery('body').css('padding-top','0');
	}
});
*/
